<script setup>
    import { defineAsyncComponent, computed } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    
    const store = useStore();
    const { t } = useI18n();

    const price = computed(() => store.getters.getCurrentPrice)

    console.log(price.value)

    const handleSaveConfiguration = () => {
        store.dispatch('modal/activateModal', {
            title: t('components.configurator.summary-offcanvas-content.text9'),
            contentComponent: defineAsyncComponent(() => import('@components/modals/SaveConfiguration.vue')),
        })
    }

    const handleAvailabilityNotification = () => {
        store.dispatch('modal/activateModal', {
            title: t('components.configurator.summary-offcanvas-content.text10'),
            contentComponent: defineAsyncComponent(() => import('@components/modals/AvailabilityNotification.vue')),
        })
    }
</script>

<template>
    <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between mt-4 gap-4">
        <a href="#" class="d-flex text-dark text-underline fw-bold gap-3 align-items-center" @click="handleSaveConfiguration">
            <div class="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center" style="min-width:34px;min-height:34px;">
                <font-awesome-icon icon="fa-light fa-floppy-disk" size="lg"/>
            </div>

            {{ $t('components.configurator.summary-offcanvas-content.text3') }}
        </a>

        <a v-if="price" href="#" class="d-flex text-dark text-underline fw-bold gap-3 align-items-center" data-bs-toggle="offcanvas" data-bs-target="#summary-offcanvas">
            <span class="d-none d-lg-inline">
                {{ $t('components.configurator.configurator-summary-header-desktop.text1') }}
            </span>

            <div class="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center" style="min-width:34px;min-height:34px;">
                <font-awesome-icon icon="fa-light fa-bag-shopping" size="lg"/>
            </div>

            <span class="d-lg-none">
                {{ $t('components.configurator.configurator-summary-header-desktop.text1') }}
            </span>
        </a>

        <a v-else href="#" class="d-flex text-dark text-underline fw-bold gap-3 align-items-center" @click="handleAvailabilityNotification">
            <span class="d-none d-lg-inline">
                {{ $t('components.configurator.summary-offcanvas-content.text10') }}
            </span>

            <div class="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center" style="min-width:34px;min-height:34px;">
                <font-awesome-icon icon="fa-light fa-bell" size="lg"/>
            </div>

            <span class="d-lg-none">
                {{ $t('components.configurator.summary-offcanvas-content.text10') }}
            </span>
        </a>
    </div>
</template>