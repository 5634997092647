export const handleAddToCartInIframe = (html) => {
    window.parent.postMessage({ type: 'ADDED_TO_CART', data: { html }}, '*');
}

export const handleAddToCartOnSite = () => {
    const domainToCartLinkMap = {
        'savicki.pl': 'https://savicki.pl/koszyk',
        'savicki.co.uk': 'https://savicki.com/cart',
        'savicki.com': 'https://savicki.com/cart',
        'savicki.cz': 'https://savicki.cz/kosik',
        'savicki.sk': 'https://savicki.sk/kosik',
        'savicki.bg': 'https://savicki.bg/koshnica',
        'savicki.gr': 'https://savicki.gr/cart',
        'savicki.hu': 'https://savicki.hu/kosar',
        'savicki.de': 'https://savicki.de/warenkorb',
        'savicki.ro': 'https://savicki.ro/cos',
        'savicki.hr': 'https://savicki.hr/cart',
        pl: 'https://savicki.pl/koszyk',
        'co.uk': 'https://savicki.com/cart',
        'com': 'https://savicki.com/cart',
        'cz': 'https://savicki.cz/kosik',
        'sk': 'https://savicki.sk/kosik',
        'bg': 'https://savicki.bg/koshnica',
        'gr': 'https://savicki.gr/cart',
        'hu': 'https://savicki.hu/kosar',
        'de': 'https://savicki.de/warenkorb',
        'ro': 'https://savicki.ro/cos',
        'hr': 'https://savicki.hr/cart'
    }

    const link = domainToCartLinkMap[window.domain] || domainToCartLinkMap['com'];

    window.location.href = link;
}