<script setup>
import { useStore } from 'vuex';

import SummaryOffcanvasDesktop from '@components/SummaryOffcanvasDesktop.vue';

const store = useStore();
</script>

<template>
    <SummaryOffcanvasDesktop id="summary-offcanvas" />

    <div class="d-none d-md-flex align-items-center justify-content-between mb-4">
        <div>
            <strong class="fs-5">{{
                $filters.getPriceFormatted(store.getters.getCurrentPrice)
            }}</strong>

            <div class="d-flex justify-content-between align-items-center gap-2">
                <p v-if="store.getters.getDiscountedPrice" class="mb-0 text-danger fs-8 fw-bold">
                    -{{ $filters.getPriceFormatted(store.getters.getDiscountedPrice) }}
                </p>
                <p v-if="store.getters.getDiscountedPrice" class="mb-0 fs-8">
                    <s>{{ $filters.getPriceFormatted(store.getters.getOldPrice) }}</s>
                </p>
            </div>
        </div>
    </div>
</template>
