<template>
    <div></div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, nextTick } from 'vue';
import { useShepherd } from 'vue-shepherd';
import { useI18n } from 'vue-i18n';
import Cookies from 'js-cookie';

const { t } = useI18n();
const store = useStore();
// const isConfiguratorSummaryLoaded = computed(() => store.state.configuratorSummaryLoaded);
const tour = useShepherd({ useModalOverlay: true });

function setTourCompletedCookie() {
    Cookies.set('tourCompleted', 'true', { expires: 30, path: '/' });
}

const isTourCompleted = Cookies.get('tourCompleted'); ///

tour.on('complete', setTourCompletedCookie);
tour.on('cancel', setTourCompletedCookie);

const isMobile = ref(false);

function checkMediaQuery() {
    const query = window.matchMedia('(max-width: 768px)');
    isMobile.value = query.matches;
}

function scrollToElement(element) {
    nextTick(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
}

const setupTour = () => {
    const collectionElement = document.querySelector('[data-collapse-key="collectionCollapse"]');
    const metalElement = document.querySelector('[data-collapse-key="metalCollapse"]');
    const massElement = document.querySelector('[data-collapse-key="main_stone_massCollapse"]');
    const engraveElement = document.querySelector('[data-collapse-key="engraveCollapse"]');
    const apvizElement = document.querySelector('[data-shepherd-key="apvizPreview"]');
    const collapseWrapper = document.querySelector('[data-shepherd-key="configurator-summary"]');

    const steps = [
        {
            attachTo: {
                element: isMobile.value ? collapseWrapper : collectionElement,
                on: 'bottom',
            },
            text: t('tour.text1'),
            arrow: true,
            buttons: [
                {
                    text: t('tour.skip'),
                    action: tour.cancel,
                    classes: 'shepherd-button-secondary',
                },
                {
                    action() {
                        return this.next();
                    },
                    text: t('tour.next'),
                },
            ],
            when: {
                show() {
                    store.commit('toggleFacetCollapse', 'collection');
                    scrollToElement(collectionElement);
                },
                hide() {
                    // store.commit('closeFacetCollapse', 'collection');
                },
            },
        },
        {
            attachTo: { element: isMobile.value ? collapseWrapper : metalElement, on: 'bottom' },
            text: t('tour.text2'),
            buttons: [
                {
                    action() {
                        return this.back();
                    },
                    classes: 'shepherd-button-secondary',
                    text: t('tour.back'),
                },
                {
                    action() {
                        return this.next();
                    },
                    text: t('tour.next'),
                },
            ],
            when: {
                show() {
                    store.commit('toggleFacetCollapse', 'metal');
                    scrollToElement(metalElement);
                },
                hide() {
                    // store.commit('closeFacetCollapse', 'metal');
                },
            },
        },
        {
            attachTo: { element: isMobile.value ? collapseWrapper : massElement, on: 'bottom' },
            text: t('tour.text3'),
            buttons: [
                {
                    action() {
                        return this.back();
                    },
                    classes: 'shepherd-button-secondary',
                    text: t('tour.back'),
                },
                {
                    action() {
                        return this.next();
                    },
                    text: t('tour.next'),
                },
            ],
            when: {
                show() {
                    store.commit('toggleFacetCollapse', 'main_stone_mass');
                    scrollToElement(massElement);
                },
                hide() {
                    // store.commit('closeFacetCollapse', 'main_stone_mass');
                },
            },
        },
        {
            attachTo: { element: isMobile.value ? collapseWrapper : engraveElement, on: 'bottom' },
            text: t('tour.text4'),
            buttons: [
                {
                    action() {
                        return this.back();
                    },
                    classes: 'shepherd-button-secondary',
                    text: t('tour.back'),
                },
                {
                    action() {
                        return this.next();
                    },
                    text: t('tour.next'),
                },
            ],
            when: {
                show() {
                    store.commit('toggleFacetCollapse', 'engrave');
                    scrollToElement(engraveElement);
                },
                hide() {
                    // store.commit('closeFacetCollapse', 'engrave');
                },
            },
        },
        {
            attachTo: { element: apvizElement, on: 'top' },
            text: t('tour.text5'),
            buttons: [
                {
                    action() {
                        return this.back();
                    },
                    classes: 'shepherd-button-secondary',
                    text: t('tour.back'),
                },
                {
                    text: t('tour.finish'),
                    action: tour.cancel,
                },
            ],
        },
    ];
    steps.forEach((step) => tour.addStep(step));
};

window.addEventListener('ConfiguratorSummaryLoaded', () => {
    if (!isTourCompleted) {
        checkMediaQuery();
        setupTour();
        tour.start();
    }
});
</script>
